import React from 'react';
import Recorder from './recorder';
import { BrowserRouter, Routes, Route, Switch } from 'react-router-dom';
import {CompanyPage, JobDetailPage} from './jobs';
import './App.css'

function App() {
  return (
    // <div className='App'>
      <BrowserRouter>
        <Routes>
            {/* <Route path="/" element={<CompanyPage/>} /> */}
            <Route path="/:jobId" element={<JobDetailPage/>} />
        </Routes>
      </BrowserRouter>
    // </div>
  );
}

export default App;
